<template>
    <div id="registration" class="u-container">
        <div class="u-row">
            <div class="u-col u-col-12" v-if="registrationData">
                <keep-alive>
                    <router-view :registrationData="registrationData"></router-view>
                </keep-alive>
            </div>
        </div>
    </div>
</template>

<script>
    import {authService} from "../../../_api";
    export default {
        name: 'registration',
        data () {
            return {
                registrationData: null
            }
        },
        created() {
            authService.getRegData().then((res) => {
                this.registrationData = res;
            })
        },
        computed: {},
        mounted() {},
    }
</script>

<style lang="less">
    @import "../../../assets/styles/_vars";
    #registration {
        margin-top: 40px;
        margin-bottom: 40px;
        .u-row {
            .u-col {
                &>div {
                    width: 100%;
                    .page-title {
                        font-size: 26px;
                        font-weight: 500;
                        margin-bottom: 43px;
                        padding: 0 9px;
                        text-align: left;
                        color: @text_color;
                        span {
                            &:first-child {
                                color: #A7A7A7;
                            }
                        }
                    }
                }
            }
        }
    }

    // Extra small devices (portrait phones, less than 576px)
    @media (max-width: 576px) {

    }
    // Small devices (landscape phones, less than 768px)
    @media (max-width: 768px) {
        #registration {
            margin-top: 0;
            .u-row {
                .u-col {
                    padding: 0;
                }
            }
        }
    }
    // Medium devices (tablets, less than 992px)
    @media (max-width: 991px) {

    }
    // Large devices (desktops, less than 1200px)
    @media (max-width: 1200px) {

    }

</style>
