<template>
    <div id="registration-email-conf">
        <h2 class="page-title">
            Проверьте вашу почту
        </h2>
        <div class="c-wrapper">
            <div class="form-wrapper">
                <img class="email-img" src="../../../../assets/img/mail.svg" alt="email">
                <div class="content">
                    <p class="content-descr"><span v-if="isResetPassword">Для изменения пароля</span><span v-else>Для завершения регистрации</span> нажмите на ссылку в письме, которое мы отправили на:</p>
                    <p v-if="isResetPassword" class="email-send-to">{{resetEmail}}</p>
                    <p v-else class="email-send-to">{{userData.email}}</p>
                    <p class="email-resend-btn" @click="resendEmail">Не получили письмо?</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {authService} from "../../../../_api";

    export default {
        name: 'registration-email-conf',
        components: {},
        data() {
           return {
               userData: '',
               resetEmail: '',
               isResetPassword: false
           }
        },
        created () {
            console.log(this.$route.query.resetPassword);
            console.log(this.$route.query.email);
            if (this.$route.query.resetPassword) {
                this.isResetPassword = true;
                this.resetEmail = this.$route.query.email;
            } else {
                let regData = JSON.parse(localStorage.getItem('uReg'));
                if (regData && regData.sOne) {
                    this.userData = regData.sOne;
                } else {
                    // this.$router.push('/registration/step-one');
                }
            }

        },
        mounted() {},
        computed: {},
        methods: {
            resendEmail() {
                let method = null;
                if (this.isResetPassword) {
                    let obj = {
                        email: this.resetEmail
                    }
                    method = authService.resetPasswordSendEmail(obj);
                } else {
                    let obj = {
                        email: this.userData.email
                    }
                    method = authService.resendEmail(obj);
                }

                method.then((res) => {
                    if (res) {
                        this.$store.dispatch('alert/success', 'Письмо повторно отправлено', { root: true });
                    }
                }).catch(err => {
                    this.$store.dispatch('alert/error', err.response.data, { root: true });
                });
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "../../../../assets/styles/_vars";
    #registration-email-conf {
        .c-wrapper {
            background-color: #F8F8F8;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            padding: 48px 57px;
            .form-wrapper {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: nowrap;
                .email-img {
                    margin-right: 30px;
                }
                .content {
                    p {
                        font-size: 18px;
                        font-weight: 400;
                        color: #666666;
                        text-align: left;
                        margin: 0;
                    }
                    .content-descr {
                        text-align: left;
                        max-width: 416px;
                    }
                    .email-send-to {
                        color: @default_selection;
                        font-style: italic;
                        margin-bottom: 40px;
                    }
                    .email-resend-btn {
                        color: @default_selection;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    // Large devices (desktops, less than 1200px)
    @media (max-width: 1200px) {}
    // Medium devices (tablets, less than 992px)
    @media (max-width: 991px) {}
    // Small devices (landscape phones, less than 768px)
    @media (max-width: 768px) {
        #registration-email-conf {
            .page-title {
                font-size: 20px!important;
            }
            .c-wrapper {
                padding: 15px 15px 15px 15px;
                flex-wrap: wrap;
                .form-wrapper {
                    .content {
                        p {
                            font-size: 15px;
                        }
                    }
                }
            }
        }
    }
    // Extra small devices (portrait phones, less than 576px)
    @media (max-width: 576px) {}
</style>
