<template>
    <div id="registration-step-three">
        <h1 class="page-title">
            <span>Шаг 3 из 3. </span>
            <span>Ваши увлечения и интересы</span>
        </h1>
        <div class="c-wrapper">
            <div class="form-wrapper">
                <ValidationObserver v-slot="{invalid, handleSubmit}" ref="form">
                    <form class="u-col-12" v-on:submit.prevent="handleSubmit(onSubmit($event))">
                        <div class="hobbies-and-interests-title">
                            Выберите, пожалуйста, одну ини несколько категорий интересов для того, чтобы система Up&UP могла составить перечень вопросов, касающихся Ваших житейских факторов и подобрать друзей для общения
                        </div>
                        <div class="form-group-wrapper">
                            <div class="basic-user-data">
                                <div class="radio-buttons-wrapper">
                                    <div class="form-group u-radiobutton-wr no-icon" v-for="i of hobbies_and_interests_options">
                                        <ValidationProvider rules="" v-slot="{classes, errors}">
                                            <input :id="i.label + i.id" type="radio" :value="i.label" v-model="userData.hobbies_and_interests" :name="i.label + i.id"/>
                                            <label @click="selectHobbieOrInterest(i)" :for="i.label + i.id" v-bind:class="classes">
                                                {{i.label}}
                                            </label>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>
                            <div class="privacy-settings custom-width">
                                <div class="form-group">
                                    <ValidationProvider rules="required" v-slot="{classes, errors}">
                                        <privacy-dropdown-reg v-model="userData.hobbies_and_interests_privacy"></privacy-dropdown-reg>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>

                        <div class="suboptions-wrapper" v-if="activeSubOptions">
                            <div class="suboption-item" v-for="i of activeSubOptions.listOfSubOptions" :key="i.id + '_' + i.title">
                                <div class="suboption-title">
                                    {{i.title}}
                                </div>
                                <div class="suboption-descr">
                                    {{i.descr}}
                                </div>
                                <div class="suboptions-data-list-wrapper">
                                    <multiselect :id="i.title" :placeholder="i.placeholder" :list="i.dataList" :listSelected="i.selectedList"></multiselect>
                                </div>
                            </div>
                        </div>
                    </form>
                </ValidationObserver>
                <div class="controls-wrapper">
                    <button class="blank-button postpone-btn" @click="handleSubmit(true)">пропустить и заполнить позже</button>
                    <button class="def-btn submit-btn" @click="handleSubmit(false)">Продолжить</button>
                </div>
                <!--<pre v-if="isSubmitted" style="text-align: left">{{userData}}</pre>-->
            </div>
        </div>
    </div>
</template>

<script>
    import multiselect from "../../../../components/_shared/Multiselect";
    import privacyDropdownReg from "../../../../components/_shared/PrivacyDropdownReg";

    export default {
        name: 'registration-step-three',
        data() {
            return {
                isSubmitted: false,
                privacy_options: [
                    {
                        id: 1,
                        label: 'Все',
                        iconClass: 'all'
                    }, {
                        id: 2,
                        label: 'Никто',
                        iconClass: 'no-one'
                    }
                ],
                activeSubOptions: null,
                hobbies_and_interests_options: [
                    {
                        id: 1,
                        label: 'Фильмы',
                        listOfSubOptions: [
                            {
                                id: 11,
                                title: 'Жанры кино',
                                descr: 'Какие жанры кино вам нравятся?',
                                placeholder: 'Начните вводить название жанра или выберите его из списка',
                                dataList: [],
                                selectedList: []
                            },
                            {
                                id: 22,
                                title: 'Фильмы',
                                descr: 'Какие фильмы вам нравятся??',
                                placeholder: 'Начните вводить название фильма или выберите его из списка',
                                dataList: [],
                                selectedList: []
                            },
                            {
                                id: 33,
                                title: 'Актеры',
                                descr: 'Ваши любимые актеры?',
                                placeholder: 'Начните вводить имя актера или выберите его из списка',
                                dataList: [],
                                selectedList: []
                            },
                            {
                                id: 44,
                                title: 'Режиссеры',
                                descr: 'Творчество каких режиссеров вам нравится??',
                                placeholder: 'Начните вводить имя режиссера или выберите его из списка',
                                dataList: [],
                                selectedList: []
                            },
                            {
                                id: 55,
                                title: 'Студии / страны производства',
                                descr: 'Фильмы каких студий / стран производства вам нравятся??',
                                placeholder: 'Начните вводить название студии / страны или выберите ее из списка',
                                dataList: [],
                                selectedList: []
                            },
                        ]
                    },
                    {
                        id: 2,
                        label: 'Музыка',
                        listOfSubOptions: [
                            {
                                id: 11,
                                title: 'Cтили музыки',
                                descr: 'Какие стили музыки вам нравятся?',
                                placeholder: 'Начните вводить название стиля или выберите его из списка',
                                dataList: [],
                                selectedList: []
                            },
                            {
                                id: 22,
                                title: 'Исполнители',
                                descr: 'Какие исполнители вам нравятся?',
                                placeholder: 'Начните вводить имя исполнителя или выберите его из списка',
                                dataList: [],
                                selectedList: []
                            },
                        ]
                    },
                    {
                        id: 3,
                        label: 'Книги',
                        listOfSubOptions: [
                            {
                                id: 11,
                                title: 'Книги',
                                descr: 'Какие книги вам нравятся?',
                                placeholder: 'Начните вводить название книги или выберите его из списка',
                                dataList: [],
                                selectedList: []
                            }
                        ]
                    },
                    {
                        id: 4,
                        label: 'Компьютерные игры',
                        listOfSubOptions: [
                            {
                                id: 11,
                                title: 'Компьютерные игры',
                                descr: 'Какие компьютерные игры вам нравятся?',
                                placeholder: 'Начните вводить название компьютерные игры или выберите его из списка',
                                dataList: [],
                                selectedList: []
                            }
                        ]
                    },
                    {
                        id: 5,
                        label: 'Религия',
                        listOfSubOptions: [
                            {
                                id: 11,
                                title: 'Религия',
                                descr: 'Какие религия вам нравятся?',
                                placeholder: 'Начните вводить название религия или выберите его из списка',
                                dataList: [],
                                selectedList: []
                            }
                        ]
                    },
                    {
                        id: 6,
                        label: 'Домашние животные',
                        listOfSubOptions: [
                            {
                                id: 11,
                                title: 'Домашние животные',
                                descr: 'Какие Домашние животные вам нравятся?',
                                placeholder: 'Начните вводить название Домашние животные или выберите его из списка',
                                dataList: [],
                                selectedList: []
                            }
                        ]
                    },
                    {
                        id: 7,
                        label: 'Спорт',
                        listOfSubOptions: [
                            {
                                id: 11,
                                title: 'Спорт',
                                descr: 'Какие Спорт вам нравятся?',
                                placeholder: 'Начните вводить название Спорт или выберите его из списка',
                                dataList: [],
                                selectedList: []
                            }
                        ]
                    },
                    {
                        id: 8,
                        label: 'Путешествия',
                        listOfSubOptions: [
                            {
                                id: 11,
                                title: 'Путешествия',
                                descr: 'Какие Путешествия вам нравятся?',
                                placeholder: 'Начните вводить название Путешествия или выберите его из списка',
                                dataList: [],
                                selectedList: []
                            }
                        ]
                    },
                    {
                        id: 9,
                        label: 'Развлечения / Времьяпровождение',
                        listOfSubOptions: [
                            {
                                id: 11,
                                title: 'Развлечения / Времьяпровождение',
                                descr: 'Какие Развлечения / Времьяпровождение вам нравятся?',
                                placeholder: 'Начните вводить название Развлечения / Времьяпровождение или выберите его из списка',
                                dataList: [],
                                selectedList: []
                            }
                        ]
                    },
                    {
                        id: 10,
                        label: 'Хобби',
                        listOfSubOptions: [
                            {
                                id: 11,
                                title: 'Хобби',
                                descr: 'Какие Хобби вам нравятся?',
                                placeholder: 'Начните вводить название Хобби или выберите его из списка',
                                dataList: [],
                                selectedList: []
                            }
                        ]
                    },
                ],
                userData: {
                    hobbies_and_interests: '',
                    hobbies_and_interests_privacy: [],
                    hobbies_and_interests_selected: []
                },
            }
        },
        components: {
            'multiselect': multiselect,
            'privacy-dropdown-reg': privacyDropdownReg
        },
        computed: {},
        methods: {
            handleSubmit(postpone) {
                if (postpone) {
                    this.$router.push('/registration/email-confirmation');
                    let tempData = JSON.parse(localStorage.getItem('uReg'));
                    tempData['sThree'] = null;
                    localStorage.setItem('uReg', JSON.stringify(tempData));
                } else {
                    this.$refs.form.validate().then(success => {
                        if (!success) {
                            return;
                        } else {
                            this.isSubmitted = true;
                            this.userData.hobbies_and_interests_selected = this.hobbies_and_interests_options;
                            this.$router.push('/registration/email-confirmation');
                            let tempData = JSON.parse(localStorage.getItem('uReg'));
                            tempData['sThree'] = this.userData;
                            localStorage.setItem('uReg', JSON.stringify(tempData));
                        }
                    })
                }
            },
            postponeForm() {
                this.$router.push('/registration/email-confirmation');
            },
            selectHobbieOrInterest(data) {
                this.activeSubOptions = data;
            }
        },
        created () {},
        mounted() {
            let regData = JSON.parse(localStorage.getItem('uReg'));
            if (regData && regData.sOne) {
                this.hobbies_and_interests_options.forEach(topObj => {
                    for (let i = 0; i < 20; i++) {
                        topObj.listOfSubOptions.forEach(obj => {
                            obj.dataList.push({
                                id: i + 1,
                                name: `${obj.title}_${i + 1}`
                            })
                        })
                    }
                });
            } else {
                this.$router.push('/registration/step-one');
            }
        }
    }
</script>

<style lang="less">
    @import "../../../../assets/styles/_vars";
    #registration-step-three {
        .c-wrapper {
            .form-wrapper {
                form {
                    .hobbies-and-interests-title {
                        font-size: 13px;
                        color: @text_color;
                        margin-bottom: 20px;
                        text-align: left;
                    }
                }
                .form-group-wrapper {
                    .basic-user-data {
                        width: 75%;
                        .radio-buttons-wrapper {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            flex-wrap: wrap;
                            .form-group {
                                margin: 0;
                                width: auto;
                                border-radius: 3px;
                                position: relative;
                                margin-right: 5px;
                                margin-bottom: 5px;
                                span {
                                    display: flex;
                                    justify-content: flex-start;
                                    align-items: center;
                                    flex-wrap: nowrap;
                                }
                            }
                        }
                    }
                }
                .suboptions-wrapper {
                    .suboption-item {
                        margin-bottom: 21px;
                        .suboption-title {
                            font-size: 15px;
                            font-weight: 500;
                            text-transform: uppercase;
                            color: @text_color;
                            text-align: left;
                            margin-bottom: 16px;
                        }
                        .suboption-descr {
                            font-size: 15px;
                            font-weight: 400;
                            color: @text_color;
                            text-align: left;
                            margin-bottom: 8px;
                        }
                    }
                }
            }
        }
    }
</style>
