var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"registration-step-three"}},[_vm._m(0),_c('div',{staticClass:"c-wrapper"},[_c('div',{staticClass:"form-wrapper"},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"u-col-12",on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.onSubmit($event))}}},[_c('div',{staticClass:"hobbies-and-interests-title"},[_vm._v(" Выберите, пожалуйста, одну ини несколько категорий интересов для того, чтобы система Up&UP могла составить перечень вопросов, касающихся Ваших житейских факторов и подобрать друзей для общения ")]),_c('div',{staticClass:"form-group-wrapper"},[_c('div',{staticClass:"basic-user-data"},[_c('div',{staticClass:"radio-buttons-wrapper"},_vm._l((_vm.hobbies_and_interests_options),function(i){return _c('div',{staticClass:"form-group u-radiobutton-wr no-icon"},[_c('ValidationProvider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userData.hobbies_and_interests),expression:"userData.hobbies_and_interests"}],attrs:{"id":i.label + i.id,"type":"radio","name":i.label + i.id},domProps:{"value":i.label,"checked":_vm._q(_vm.userData.hobbies_and_interests,i.label)},on:{"change":function($event){return _vm.$set(_vm.userData, "hobbies_and_interests", i.label)}}}),_c('label',{class:classes,attrs:{"for":i.label + i.id},on:{"click":function($event){return _vm.selectHobbieOrInterest(i)}}},[_vm._v(" "+_vm._s(i.label)+" ")])]}}],null,true)})],1)}),0)]),_c('div',{staticClass:"privacy-settings custom-width"},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('privacy-dropdown-reg',{model:{value:(_vm.userData.hobbies_and_interests_privacy),callback:function ($$v) {_vm.$set(_vm.userData, "hobbies_and_interests_privacy", $$v)},expression:"userData.hobbies_and_interests_privacy"}})]}}],null,true)})],1)])]),(_vm.activeSubOptions)?_c('div',{staticClass:"suboptions-wrapper"},_vm._l((_vm.activeSubOptions.listOfSubOptions),function(i){return _c('div',{key:i.id + '_' + i.title,staticClass:"suboption-item"},[_c('div',{staticClass:"suboption-title"},[_vm._v(" "+_vm._s(i.title)+" ")]),_c('div',{staticClass:"suboption-descr"},[_vm._v(" "+_vm._s(i.descr)+" ")]),_c('div',{staticClass:"suboptions-data-list-wrapper"},[_c('multiselect',{attrs:{"id":i.title,"placeholder":i.placeholder,"list":i.dataList,"listSelected":i.selectedList}})],1)])}),0):_vm._e()])]}}])}),_c('div',{staticClass:"controls-wrapper"},[_c('button',{staticClass:"blank-button postpone-btn",on:{"click":function($event){return _vm.handleSubmit(true)}}},[_vm._v("пропустить и заполнить позже")]),_c('button',{staticClass:"def-btn submit-btn",on:{"click":function($event){return _vm.handleSubmit(false)}}},[_vm._v("Продолжить")])])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h1',{staticClass:"page-title"},[_c('span',[_vm._v("Шаг 3 из 3. ")]),_c('span',[_vm._v("Ваши увлечения и интересы")])])}]

export { render, staticRenderFns }